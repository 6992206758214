<template>
  <div class="outer_container">
    <MyBreadCrumb :breadMenu="breadMenu"></MyBreadCrumb>
    <div class="inner_content_container">
      <div class="outer_page" @scroll="outerScroll">
        <div class="main_box">
          <div id="main" style="width: 500px;height:500px;"></div>
        </div>
        <div class="right_content">
          <div
            class="nav_scroll my_scroll"
            :class="{ fixed_title: titleFixedStatus }"
          >
            <template v-for="navItem in questionList">
              <span
                :style="{
                  color: templateId == navItem.gameId ? themeColor : 'inherit'
                }"
                class="nav_title"
                @click="chooseTemplate(navItem.template, navItem.gameId)"
                :key="navItem.gameId"
              >
                {{ navItem.title }}
              </span>
            </template>
          </div>
          <div class="empty_nav" v-if="titleFixedStatus"></div>
          <div class="inner_content">
            <template v-for="item in questionList">
              <div
                v-if="item.template === template && item.gameId === templateId"
                :key="item.gameId"
              >
                <!-- 听力选图 -->
                <listen-choose-pic
                  v-if="
                    item.template === '/pages/discover/listen-choose-pic/main'
                  "
                  :item="item"
                ></listen-choose-pic>
                <!-- 听力选答 -->
                <listen-choose-answer
                  v-if="template === '/pages/discover/listen-choose-answe/main'"
                  :item="item"
                ></listen-choose-answer>
                <!-- 听对话选答  -->
                <listen-dialog-choose
                  v-if="
                    template === '/pages/discover/choose-dialog-answer/main'
                  "
                  :item="item"
                ></listen-dialog-choose>
                <!-- 听后选择 -->
                <after-listen
                  v-if="template === '/pages/discover/after-listen/main'"
                  :item="item"
                ></after-listen>
                <!-- 听力填空 -->
                <listen-fill-blank
                  v-if="
                    template === '/pages/discover/listen-fill-blank/main' ||
                      template === 'new-fill-blank'
                  "
                  :item="item"
                ></listen-fill-blank>
                <!-- 朗读课文 -->
                <unit-text-speak
                  v-if="template === '/pages/discover/unit-text-speak/main'"
                  :item="item"
                ></unit-text-speak>
                <!-- 情景问答 -->
                <scene-answer
                  v-if="
                    template === '/pages/discover/scene-question-answer/main'
                  "
                  :item="item"
                ></scene-answer>
                <!-- 话题阐述 -->
                <topic-exposition
                  v-if="template === '/pages/discover/topic-exposition/main'"
                  :item="item"
                ></topic-exposition>
                <!-- 听后回答 -->
                <listen-answer
                  v-if="
                    template === '/pages/discover/listen-answer/main' ||
                      template === '/pages/discover/listen-speak/main'
                  "
                  :item="item"
                ></listen-answer>
                <!-- 听后转述 -->
                <listen-report
                  v-if="template === '/pages/discover/listen-report/main'"
                  :item="item"
                ></listen-report>
                <!-- 识读音标 -->
                <identify-symbol
                  v-if="template === '/pages/discover/identify-symbol/main'"
                  :item="item"
                ></identify-symbol>
                <!-- 听一个对话回答多问题 -->
                <listen-passage
                  v-if="template === 'listen-essay-answer'"
                  :item="item"
                ></listen-passage>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyBreadCrumb from '../../components/BreadCrumb';
import { fetchReportDetail } from '../../api/service';
import globalConfig from '@/store/global_config';
import echarts from 'echarts';
import ListenChoosePic from '../Exam/components/unit-report/listen-choose-pic';
import ListenChooseAnswer from '../Exam/components/unit-report/listen-choose-answer';
import ListenDialogChoose from '../Exam/components/unit-report/listen-dialog-choose';
import IdentifySymbol from '../Exam/components/unit-report/identify-symbol';
import ListenFillBlank from '../Exam/components/unit-report/listen-fill-blank';
import UnitTextSpeak from '../Exam/components/unit-report/unit-text-speak';
import SceneAnswer from '../Exam/components/unit-report/scene-answer';
import TopicExposition from '../Exam/components/unit-report/topic-exposition';
import AfterListen from '../Exam/components/unit-report/after-listen';
import ListenAnswer from '../Exam/components/unit-report/listen-answer';
import ListenReport from '../Exam/components/unit-report/listen-report';
import ListenPassage from '../Exam/components/unit-report/listen-passage';
import { showMyToast, showLoading, hideLoading } from '../../utils/common';
export default {
  components: {
    ListenChoosePic,
    ListenChooseAnswer,
    ListenDialogChoose,
    IdentifySymbol,
    ListenFillBlank,
    UnitTextSpeak,
    SceneAnswer,
    TopicExposition,
    AfterListen,
    ListenAnswer,
    ListenReport,
    ListenPassage,
    MyBreadCrumb
  },
  data() {
    return {
      titleFixedStatus: false,
      questionList: [],
      template: '',
      templateId: '',
      breadMenu: []
    };
  },

  beforeDestroy() {
    globalConfig.commit('stopAudio');
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },

  mounted() {
    const { bookId, chapterId, typeId, pId } = this.$route.params;
    this.bookId = bookId;
    this.chapterId = chapterId;
    this.typeId = typeId;
    this.pId = pId;
    this.fetchExamDetail();
    this.breadMenu = [
      {
        title: '选择教材',
        path: '/book'
      },
      {
        title: '教材详情',
        path: `/book/${this.bookId}`
      },
      {
        title: '学习报告',
        isBack: true
      },
      {
        title: '报告详情'
      }
    ];
  },

  methods: {
    outerScroll(event) {
      const scrollTop = event.target.scrollTop;
      if (scrollTop > 500) {
        this.titleFixedStatus = true;
      } else {
        this.titleFixedStatus = false;
      }
    },

    fetchExamDetail() {
      fetchReportDetail({
        book_id: this.bookId,
        book_chapter_id: this.chapterId,
        type_id: this.typeId,
        practice_count_id: this.pId
      }).then(response => {
        const { data } = response;
        if (!data) {
          showMyToast('w', '暂无数据');
          setTimeout(() => {
            this.$router.back();
          }, 1000);
          return;
        }
        this.columnShow(this.handleFormatDataToArr(data));
        this.questionList = data;
        this.templateId = Number(data[0].gameId);
        this.template = data[0].template.trim();
      });
    },

    // 格式化数据获得一系列数据
    handleFormatDataToArr(arr) {
      const titleArr = [];
      const scoreArr = [];
      const allScoreArr = [];
      let sumScores = 0;
      let myScores = 0;
      arr.forEach(item => {
        const { title, score, sumScore } = item;
        myScores += Number(score);
        sumScores += Number(sumScore);
        titleArr.push({
          name: title,
          max: +sumScore + 5
        });
        scoreArr.push(score);
        allScoreArr.push(sumScore);
      });
      return {
        titleArr,
        scoreArr,
        allScoreArr,
        myScores: Number(myScores).toFixed(2),
        sumScores: Number(sumScores).toFixed(2)
      };
    },

    columnShow(obj) {
      const { titleArr, scoreArr, allScoreArr, myScores, sumScores } = obj;
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('main'));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '报告解析'
        },
        tooltip: {},
        legend: {
          data: [`总分(${sumScores})`, `我的得分(${myScores})`]
        },
        radar: {
          indicator: titleArr,
          name: {
            textStyle: {
              bordrRadius: 3,
              padding: [3, 5]
            }
          }
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                value: allScoreArr,
                name: `总分(${sumScores})`
              },
              {
                value: scoreArr,
                name: `我的得分(${myScores})`
              }
            ]
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    chooseTemplate(template, gameId) {
      if (this.template === template.trim() && this.templateId === gameId) {
        return;
      }
      globalConfig.commit('stopAudio');
      showLoading();
      this.template = template.trim();
      this.templateId = Number(gameId);
      setTimeout(() => {
        hideLoading();
      }, 500);
    }
  }
};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 1024px) {
  .outer_page {
    flex-direction: column;
    overflow-y: scroll;
  }
  .right_content {
    overflow: unset !important;
    box-sizing: border-box;
    background-color: white;
    .report_content {
      overflow: unset !important;
    }
  }
}

.outer_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .inner_content_container {
    flex: 2;
    position: relative;
  }
}

.main_box {
  justify-content: space-around;
  background-color: white;
  height: 500px;
  padding-top: 20px;
  box-sizing: border-box;
  #main {
    margin: 0 auto;
  }
}
.outer_page {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  background: white;
  padding: 30px;
  overflow: scroll;
  .right_content {
    flex: 2;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .nav_scroll {
      top: 0;
      z-index: 100;
      white-space: nowrap;
      box-sizing: border-box;
      height: 70px;
      line-height: 70px;
      overflow-x: scroll;
      background: white;
      overflow-y: hidden;
      &.fixed_title {
        position: fixed;
        top: 136px;
        left: 5%;
        width: 90%;
        border-bottom: solid 1px #f5f5f5;
      }
      .nav_title {
        display: inline-block;
        text-align: center;
        padding: 0 20px;
      }
    }
    .empty_nav {
      height: 70px;
    }
    .inner_content {
      height: 100%;
      overflow-y: scroll;
    }
  }
}
.content_box {
  padding-top: 70px;
}
</style>
