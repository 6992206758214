var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outer_container"},[_c('MyBreadCrumb',{attrs:{"breadMenu":_vm.breadMenu}}),_c('div',{staticClass:"inner_content_container"},[_c('div',{staticClass:"outer_page",on:{"scroll":_vm.outerScroll}},[_vm._m(0),_c('div',{staticClass:"right_content"},[_c('div',{staticClass:"nav_scroll my_scroll",class:{ fixed_title: _vm.titleFixedStatus }},[_vm._l((_vm.questionList),function(navItem){return [_c('span',{key:navItem.gameId,staticClass:"nav_title",style:({
                color: _vm.templateId == navItem.gameId ? _vm.themeColor : 'inherit'
              }),on:{"click":function($event){return _vm.chooseTemplate(navItem.template, navItem.gameId)}}},[_vm._v(" "+_vm._s(navItem.title)+" ")])]})],2),(_vm.titleFixedStatus)?_c('div',{staticClass:"empty_nav"}):_vm._e(),_c('div',{staticClass:"inner_content"},[_vm._l((_vm.questionList),function(item){return [(item.template === _vm.template && item.gameId === _vm.templateId)?_c('div',{key:item.gameId},[(
                  item.template === '/pages/discover/listen-choose-pic/main'
                )?_c('listen-choose-pic',{attrs:{"item":item}}):_vm._e(),(_vm.template === '/pages/discover/listen-choose-answe/main')?_c('listen-choose-answer',{attrs:{"item":item}}):_vm._e(),(
                  _vm.template === '/pages/discover/choose-dialog-answer/main'
                )?_c('listen-dialog-choose',{attrs:{"item":item}}):_vm._e(),(_vm.template === '/pages/discover/after-listen/main')?_c('after-listen',{attrs:{"item":item}}):_vm._e(),(
                  _vm.template === '/pages/discover/listen-fill-blank/main' ||
                    _vm.template === 'new-fill-blank'
                )?_c('listen-fill-blank',{attrs:{"item":item}}):_vm._e(),(_vm.template === '/pages/discover/unit-text-speak/main')?_c('unit-text-speak',{attrs:{"item":item}}):_vm._e(),(
                  _vm.template === '/pages/discover/scene-question-answer/main'
                )?_c('scene-answer',{attrs:{"item":item}}):_vm._e(),(_vm.template === '/pages/discover/topic-exposition/main')?_c('topic-exposition',{attrs:{"item":item}}):_vm._e(),(
                  _vm.template === '/pages/discover/listen-answer/main' ||
                    _vm.template === '/pages/discover/listen-speak/main'
                )?_c('listen-answer',{attrs:{"item":item}}):_vm._e(),(_vm.template === '/pages/discover/listen-report/main')?_c('listen-report',{attrs:{"item":item}}):_vm._e(),(_vm.template === '/pages/discover/identify-symbol/main')?_c('identify-symbol',{attrs:{"item":item}}):_vm._e(),(_vm.template === 'listen-essay-answer')?_c('listen-passage',{attrs:{"item":item}}):_vm._e()],1):_vm._e()]})],2)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main_box"},[_c('div',{staticStyle:{"width":"500px","height":"500px"},attrs:{"id":"main"}})])}]

export { render, staticRenderFns }